import { IGatsbyEdge } from 'gatsby/dist/schema/type-definitions'

export const getFluidImage = (field: any, asArray?: boolean) => {
  let sortedFields: any = asArray ? [] : {}

  if (field.edges) {
    field.edges.map((item: IGatsbyEdge<any>) => {
      const name = item.node.base.split('.')[0]

      if (asArray) {
        sortedFields.push({
          fluid: item.node.childImageSharp.fluid,
          alt: name,
        })
      } else {
        sortedFields[name] = {
          fluid: item.node.childImageSharp.fluid,
          alt: name,
        }
      }
    })
  } else {
    sortedFields.fluid = field.childImageSharp.fluid
    sortedFields.alt = field.base.split('.')[0]
  }

  return sortedFields
}

export const getFixedImage = (field: any) => {
  let sortedFields: any = {}

  sortedFields.fixed = field.childImageSharp.fixed
  sortedFields.alt = field.base.split('.')[0]

  return sortedFields
}
