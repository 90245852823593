import React, { FunctionComponent, CSSProperties } from 'react'
import { FixedObject, FluidObject } from 'gatsby-image'
import Img from 'gatsby-image/withIEPolyfill'

interface Props {
  fluid?: FluidObject
  fixed?: FixedObject
  alt?: string
  style?: CSSProperties
}

const Image: FunctionComponent<Props> = ({ fluid, fixed, alt, ...rest }) => {
  if (fixed) {
    return <Img fixed={fixed} alt={alt} {...rest} />
  } else if (fluid) {
    return <Img fluid={fluid} alt={alt} {...rest} />
  } else {
    return <div>Picture not found</div>
  }
}

export default Image
